import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AlertaSnackComponent } from 'src/app/components/principales/alerta-snack/alerta-snack.component';
import { ConfirmacionSnackComponent } from 'src/app/components/principales/confirmacion-snack/confirmacion-snack.component';
import { NotificacionSnackComponent } from 'src/app/components/principales/notificacion-snack/notificacion-snack.component';

@Injectable()
export class NotificacionService {
  constructor(private snack: MatSnackBar, private _zone: NgZone, private router: Router) {}

  openNotif(
    title: string,
    mensaje: string,
    tipo: { alerta?: boolean; success?: boolean; purchaseSuccess?: boolean; info?: boolean; favorite?: boolean},
    duration = 0,
    verticalPosition: MatSnackBarVerticalPosition = 'bottom',
  ) {
    const t = this._zone.run(() => {
      const ref = this.snack.openFromComponent(NotificacionSnackComponent, {
        data: { title, mensaje, ...tipo },
        duration,
        horizontalPosition: 'center',
        verticalPosition: verticalPosition
      });
      ref.instance.closed.subscribe((bool) => {
        if (bool) {
          ref.dismiss();
        }
      });
      return ref;
    });
    return t;
  }
  openConfirmacion(
    mensaje: string,
    botonCancelacion: string,
    botonConfirmacion: string,
    duration = 0
  ): MatSnackBarRef<ConfirmacionSnackComponent> {
    const t = this._zone.runTask(()=>{
      const ref = this.snack.openFromComponent(ConfirmacionSnackComponent, {
        data: { mensaje,botonCancelacion,botonConfirmacion},
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return ref
    })
    return t
  }
  /**Snackbar específica para la búsqueda en Pages*/
  openAlerta(
    mensaje: string,
    botonCancelacion: string,
    botonConfirmacion: string,
    duration = 0
  ): MatSnackBarRef<AlertaSnackComponent> {
    const t = this._zone.runTask(()=>{
      const ref = this.snack.openFromComponent(AlertaSnackComponent, {
        data: { mensaje,botonCancelacion,botonConfirmacion},
        duration,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: 'animate-pulse'
      });
      ref.instance.cancel.subscribe((bool) => {
        if (bool) {
          this.router
          .navigate([], {
            skipLocationChange: false,
            queryParamsHandling: '',
          })
          .then(() => {
            window.location.reload();
          });
        }
      });
      return ref
    })
    return t
  }
}
