import { Store } from '@ngxs/store';
import { Component, Inject, Input, Optional, OnInit } from '@angular/core';
import { Datos } from 'src/app/services/utility/overlay/overlayLoaderConfig';
import { PREVIEW_DIALOG_DATA } from 'src/app/services/utility/overlay/previewLoaderData';


@Component({
  selector: 'app-loader-titulo',
  templateUrl: './loader-titulo.component.html',
  styleUrls: ['./loader-titulo.component.scss']
})
export class LoaderTituloComponent implements OnInit {
  @Input() title!: string;
  logoUrl: string = '';
  constructor(
    @Optional()
    @Inject(PREVIEW_DIALOG_DATA) public data: Datos,
    private store: Store,
  ) { }
  ngOnInit(): void {
    this.logoUrl = this.store.selectSnapshot((state:any) => state.publico.info?.loader?.logo);
  }
}
