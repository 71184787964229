import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { TokenService } from 'src/app/services/utility/token.service';
@Injectable({
  providedIn: 'root',
})
export class FarmaciaGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private tokenService: TokenService
  ) {}
  canActivate(): boolean {
    const token = this.store.selectSnapshot((state) => state.cuentas.token);
    const cliente = this.tokenService.decodeCliente(token);
    if (cliente !== environment.apiClientFarmacia) {
      this.router.navigate([''], { queryParamsHandling: 'merge' });
      return false;
    }
    return true;
  }
}
