import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alerta-snack',
  templateUrl: './alerta-snack.component.html',
  styleUrls: ['./alerta-snack.component.scss']
})
export class AlertaSnackComponent implements OnInit {
  @Output() cancel = new EventEmitter<any>(false);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {}
  ngOnInit(): void {}
}
