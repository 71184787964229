import { environment } from 'src/environments/environment';
export const headersPublico = {
  'Api-Client': environment.apiClientPublico,
  'Api-Key': environment.apiKeyPublico,
};
export const headersFarmacia = {
  'Api-Client': environment.apiClientFarmacia,
  'Api-Key': environment.apiKeyFarmacia,
};
export const headersVendedor = {
  'Api-Client': environment.apiClientVendedor,
  'Api-Key': environment.apiKeyVendedor,
}
export const rolDefault = 'Cliente';
export const RolesHabilitados = ['Farmacia', rolDefault, 'Vendedor'];
export function getHeaders(rol: string) {
  switch (rol) {
    case RolesHabilitados[0]:
      return headersFarmacia;
    case RolesHabilitados[1]:
      return headersPublico;
    case RolesHabilitados[2]:
      return headersVendedor;
  }
}
