import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Store } from '@ngxs/store';
import { TokenService } from 'src/app/services/utility/token.service';
import {
  headersFarmacia,
  headersPublico,
} from 'src/app/services/utility/publico-headers';
@Injectable({
  providedIn: 'root',
})
export class InterceptorHttp implements HttpInterceptor {
  constructor(private store: Store, private tokenService: TokenService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.store.selectSnapshot((state) => state.cuentas.token);
    const cliente = this.tokenService.decodeCliente(token);
    const page = this.store.selectSnapshot((state) => state.publico?.page);
    let headers = {
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache'
    };
    if( page ) {
      let pageHeader = {Page: page};
      headers = { ...headers, ...pageHeader};
    }
    if (!req.headers.has('Api-Client')) {
      if (!cliente || cliente === environment.apiClientPublico) {
        headers = { ...headers, ...headersPublico };
      }
      if (cliente === environment.apiClientFarmacia) {
        headers = { ...headers, ...headersFarmacia };
      }
    }
    if (!req.headers.has('Authorization')) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    req = req.clone({
      setHeaders: headers,
    });
    return next.handle(req);
  }
}
