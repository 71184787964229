<div class="app-splash-inner ">
  <div class="flex flex-col space-y-6 z-50 p-4 rounded-md bg-white border border-accent-500">
    <div class="flex flex-1 self-center">
      <ng-container *ngIf="data.success else failureLogo">
        <svg xmlns="http://www.w3.org/2000/svg" class='w-28 h-28 stroke-current text-primary-500' width="650" height="300"
          viewBox="0 0 555 554" fill="none">
          <g clip-path="url(#clip0_153_1423)">
            <path
              d="M539.373 253.38V277.475C539.34 333.951 521.053 388.904 487.238 434.138C453.422 479.371 405.891 512.462 351.732 528.475C297.574 544.488 239.69 542.565 186.714 522.993C133.737 503.421 88.5068 467.249 57.7681 419.87C27.0293 372.492 12.4292 316.447 16.1451 260.093C19.8611 203.739 41.694 150.097 78.3878 107.165C115.082 64.2332 164.67 34.3131 219.758 21.8668C274.845 9.42044 332.481 15.1148 384.067 38.1006"
              stroke="currentColor" stroke-width="31" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M539.373 67.957L277.475 330.117L198.906 251.547" stroke="currentColor" stroke-width="31"
              stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_153_1423">
              <rect width="554.95" height="554" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </ng-container>
      <ng-template #failureLogo>
        <svg xmlns="http://www.w3.org/2000/svg" class='w-28 h-28 stroke-current text-primary-500' width="650" height="300"
          viewBox="0 0 554 554" fill="none">
          <path
            d="M277 535.87C419.97 535.87 535.87 419.97 535.87 277C535.87 134.03 419.97 18.1301 277 18.1301C134.03 18.1301 18.1301 134.03 18.1301 277C18.1301 419.97 134.03 535.87 277 535.87Z"
            stroke="currentColor" stroke-width="31" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M354.661 199.339L199.339 354.661" stroke="currentColor" stroke-width="31" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M199.339 199.339L354.661 354.661" stroke="currentColor" stroke-width="31" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </ng-template>
    </div>
    <div class="flex flex-col space-y-4  flex-1 self-center">
      <div class="flex flex-1 self-center">
        <span class="text-xl  text-black text-center">
          {{data.title|transloco}}
        </span>
      </div>
      <div class="flex flex-1 mobile:flex-col justify-between items-center">
        <div *ngFor="let error of data.errores" class="flex m-2 w-full">
          <ng-container *ngIf="error.addClasses else defaultStyle">
            <button
              class="flex-1 flex justify-center w-full items-center space-x-1 rounded-md py-1 px-4 text-lg {{error.addClasses}}"
              (click)='confirmacion(error)'>
              <span class="flex-none">
                {{error.title|transloco}}
              </span>
            </button>
          </ng-container>
          <ng-template #defaultStyle>
            <button
              class="flex-1 justify-center w-full items-center space-x-1 rounded-md py-1 px-4 text-lg border-2 bg-accent-600 border-accent-600 text-white"
              (click)='confirmacion(error)'>
              <span class="flex-none">
                {{error.title|transloco}}
              </span>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="app-splash-screen">
</div>