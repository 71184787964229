export namespace PublicoActions {
  export class CloseSide {
    static readonly type = '[Publico] Close Sidenav';
    constructor() {}
  }
  export class OpenSide {
    static readonly type = '[Publico] Open Sidenav';
    constructor() {}
  }
  export class LoadMenus {
    static readonly type = '[Publico] Load Menus';
    constructor() {}
  }
  export class LoadInfo {
    static readonly type = '[Publico] Load Info';
    constructor() {}
  }
  export class UpdateState {
    static readonly type = '[Publico] Update State';
    constructor(public values: any) {}
  }
  export class CreateDevolucion {
    static readonly type = '[Publico] Create Devolucion';
    constructor() {}
  }
  export class UrlBlackList {
    static readonly type = '[Publico] Url Black List';
    constructor() {}
  }

  export class HidePrescription {
    static readonly type = '[Publico] Hide Prescription';
    constructor(public hideBtn: boolean) { }
  }
}
