import { siemprebienConfiguration } from "./site-configuration/siemprebien-configuration";

export const environment = {
  production: true,
  stage: 'qa',
  api: "https://backend-qa.atienda.com.ar/api/",
  adminUrl: "https://backend-qa.atienda.com.ar/",
  configuration: siemprebienConfiguration,
  deploymentUrl: 'qa.siemprebien.ar',
  redi: "https://red-i.com.ar/",
  portal: "https://red-i.com.ar/v2/informacion-pacientes",
  google_tag_manager_id: 'GTM-NULL',
  gmapApiKey: 'AIzaSyASACK27fYw7vqmp4bvrR2ONRP0MuzVFvU',
  apiClientPublico: 'siemprebien',
  apiKeyPublico: 'eT825uAU',
  apiClientFarmacia: 'pharma',
  apiKeyFarmacia: '2sRMeeJ6',
  apiClientVendedor: 'siemprebien',
  apiKeyVendedor: 'eT825uAU',
  googleLoginProviderId:'NULL.apps.googleusercontent.com',
  facebookLoginProviderId: 'NULL',
};