import {NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';

export const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.deploymentUrl
  },
  "position": "bottom",
  "theme": "block",
  "type": "info",
  "palette": {
    "popup": {
      "background": "#f3f4f6",
      "text": "#6c7480",
      "link": "#481475"
    },
    "button": {
      "background": "#481475",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "content":{
    "message": 'Utilizamos cookies propias y de terceros para mejorar de la experiencia de nuestros usuario. Si acepta o continúa navegando, consideramos que acepta su uso. ',
    "dismiss": "Aceptar",
    "link": 'Obtener más información',
    "href": 'https://tos.com',
  },
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": `
      <dev class="flex items-center space-x-5 mobile:space-x-2">
        <div class="flex-1 text-sm">{{message}}</div>
        <div class="flex-none">{{compliance}}</div>
      </div>
    `
  },
  elements:{
    message: `
      <span class="text-gray-600">{{message}}</span> <a href="{{href}} class="text-accent-600 underline">{{link}}</a>
    `,
  },
};