import { DatosError, Error } from "src/app/services/utility/overlay/overlayLoaderConfig";

export namespace GlobalActions {
  export class UpdateScroll {
    static readonly type = '[Global] Update Scroll';
    constructor(public scroll: number) {}
  }
  export class HideFloatMenu {
    static readonly type = '[Global] Hide Float Menu';
    constructor() {}
  }
  export class ShowFloatMenu {
    static readonly type = '[Global] Show Float Menu';
    constructor() {}
  }
  export class ShowLoader {
    static readonly type = '[Global] Show Loader';
    constructor() {}
  }
  export class HideLoader {
    static readonly type = '[Global] Hide Loader';
    constructor() {}
  }
  export class ShowError {
    static readonly type = '[Global] Show Error';
    constructor(public errores: DatosError) {}
  }
  export class HideError {
    static readonly type = '[Global] Hide Error';
    constructor(public decision: Error) {}
  }
  export class SetThemeColors {
    static readonly type = '[Global] Set Theme Colors';
    constructor(public primary: string, public accent: string) {}
  }
  export class SendErrors {
    static readonly type = '[Global] Send Errors';
    constructor(public error: any) {}
  }
}
