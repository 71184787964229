import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

export interface TrackingIdentifiers {
  id:string;
  system:string;
  description?:string;
  metadata?:any
}

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  tracking_systems_dataLayerTemplates:any = {
    viewPage: (url:string) => {
      return {
        event: 'page',
        pageName: url
      }
    },
    Pixel: {
      viewContent: (value:string, metadata:any={}) => {
        return {
          event: 'FacebookPixelViewContent',
          fb_pixel: value,
          ...metadata
        }
      },
      addToCart: (value:string, quantity:number, unitPrice:number, metadata:any={}) => {
        return {
          event: 'FacebookPixelAddToCart',
          fb_pixel: value,
          fb_quantity: quantity,
          fb_unit_price: unitPrice,
          fb_value: quantity*unitPrice,
          ...metadata
        }
      }
    }
  };

  constructor(
    private gtmService: GoogleTagManagerService,
  ) { }


  /**urlTag: {nombre de evento, url de evento} */
  pageView(url: string) {
    const gtmTag = this.tracking_systems_dataLayerTemplates['viewPage'](url);
    this.gtmService.pushTag(gtmTag);
  }

  viewContent(tracking_identifiers:TrackingIdentifiers[]) {
    tracking_identifiers.forEach( tracking_identifier => {
      const gtmTag = this.tracking_systems_dataLayerTemplates[tracking_identifier.system]['viewContent'](tracking_identifier.id, tracking_identifier.metadata);
      this.gtmService.pushTag(gtmTag);
    });
  }

  addToCart(tracking_identifiers:TrackingIdentifiers[], quantity:number, unitPrice:number) {
    tracking_identifiers.forEach( tracking_identifier => {
      const gtmTag = this.tracking_systems_dataLayerTemplates[tracking_identifier.system]['addToCart'](tracking_identifier.id, quantity, unitPrice, tracking_identifier.metadata);
      this.gtmService.pushTag(gtmTag);
    });
  }
}
