import { NavigationInterceptor } from './utility/interceptors/navigation-interceptor';
import { GlobalesModule } from 'src/app/components/globales/globales.module';
import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThirdLibModule } from './shared/third-lib/third-lib.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UpdateService } from './services/utility/updateVersion.service';
import { IconService } from './services/utility/icon.service';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/en';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { NotificacionService } from './services/utility/notificacion.service';
import { ThemingService } from './services/utility/theming.service';
import { InterceptorHttp } from './utility/interceptors/http-interceptor';
import { HelperFunctions } from './utility/pipes/helper';
import { GlobalState } from './store/global/global.state';
import { HttpErrorInterceptor } from './utility/interceptors/error-interceptor';
import { getMobile } from './services/utility/functions';
import { TokenService } from './services/utility/token.service';
import { CuentasState } from './store/cuentas/cuentas.state';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { ErroresModalComponent } from './shared/errores-modal/errores-modal.component';
import { UpdateSessionService } from './services/utility/updateSession.service';
import { MatNativeDateModule } from '@angular/material/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from "src/app/tracking/cookieconsent.config";
import { ScullyLibModule } from '@scullyio/ng-lib';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    let hammer = getMobile();
    return new Hammer(element, {
      enable: hammer,
      cssProps: {
        contentZooming: 'none',
        tapHighlightColor: 'rgba(0,0,0,0)',
        touchSelect: 'none',
        userDrag: 'none',
        touchCallout: 'none',
        userSelect: 'true',
      },
    });
  }
}
@NgModule({
  declarations: [AppComponent, ErroresModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HammerModule,
    ThirdLibModule,
    HttpClientModule,
    TranslocoRootModule,
    GlobalesModule,
    NgxsStoragePluginModule.forRoot({
      key: [
        'home.items',
        'cart.carrito.id',
        'cuentas.cuenta',
        'cuentas.rol',
        'cuentas.token',
        'cuentas.refresh',
        'cuentas.refreshTime',
        'cuentas.social',
        'cuentas.socialProvider',
        'publico.info',
      ],
    }),
    MatNativeDateModule,
    NgxsFormPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately' 
    }),
    NgxsModule.forRoot([GlobalState, CuentasState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsResetPluginModule.forRoot(),
    SocialLoginModule,
    GoogleTagManagerModule.forRoot({
      id: environment.google_tag_manager_id,
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: false,
      manualIdle: true,
    })
  ],
  providers: [
    UpdateService,
    UpdateSessionService,
    IconService,
    HelperFunctions,
    ThemingService,
    TokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorHttp,
      multi: true,
      deps: [Store, TokenService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [NotificacionService, Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NavigationInterceptor,
      multi: true,
      deps: [Store],
    },
    NotificacionService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleLoginProviderId
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookLoginProviderId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: 'googleTagManagerCSPNonce',
      useValue: 'CSP-NONCE'
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
