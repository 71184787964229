import { Component, Inject, Input, Optional} from '@angular/core';
import { Store } from '@ngxs/store';
import { Datos, DatosError, Error } from 'src/app/services/utility/overlay/overlayLoaderConfig';
import { PREVIEW_DIALOG_DATA } from 'src/app/services/utility/overlay/previewLoaderData';
import { GlobalActions } from 'src/app/store/global/global.actions';


@Component({
  selector: 'app-errores-modal',
  templateUrl: './errores-modal.component.html',
  styleUrls: ['./errores-modal.component.scss']
})
export class ErroresModalComponent {
  @Input() title!: string;
  constructor(
    private store: Store,
    @Optional()
    @Inject(PREVIEW_DIALOG_DATA) public data: DatosError,
  ) { }
  confirmacion(error: Error) {
    this.store.dispatch(new GlobalActions.HideError(error));
  }
}
