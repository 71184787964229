export function getMobile() {
  let mobileDefault = false;
  const mobile =
    '/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i';
  if (mobile.includes(navigator.userAgent)) {
    mobileDefault = true;
  } else {
    mobileDefault = false;
  }
  return mobileDefault;
}
