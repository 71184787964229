import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as urls from '../utility/urls';

@Injectable({
  providedIn: 'root',
})
export class FavoritosService {
  constructor(private http: HttpClient) {}

  getFavoritos(): Observable<any> {
    return this.http.get<any>(environment.api + urls.getFavoritos);
  }
  addFavorito(id, type: string): Observable<any> {
    return this.http.post<any>(environment.api + urls.addFavoritos, {
      publications: [{id, type}],
    });
  }
  removeFavorito(publicacion, type: string): Observable<any> {
    return this.http.post<any>(
      environment.api + urls.deleteFavorito, {
        id: publicacion,
        type
      }
    );
  }
}
