import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable()
export class IconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
  ) {}

  public registerFontAwsome(): void {
    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }

}
