import { PublicoActions } from 'src/app/store/publico/publico.actions';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs';
import { NotificacionService } from 'src/app/services/utility/notificacion.service';
import { GlobalActions } from 'src/app/store/global/global.actions';
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notif: NotificacionService, private store: Store) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const checkoutComplete = 'carrito/finalizado';
    const pictures = 'api/pictures/';
    const logos = 'get-logo';
    const staticos = 'statics';
    const busquedas = 'search/matches/';
    const busquedaCiudadesFarmacias = 'pharmacies/locations/';
    const carrito = 'shopping-carts/';
    const errors = 'errors';
    const ownedCarrito = 'owned-by/me/';
    const busquedaProducto = 'retrieve-one/';
    const loadPublicData = 'front-configurations/data';
    const busquedaContenidos = 'home-contents';
    const getClientData = 'front-configurations';
    if (
      request.url.search(pictures) === -1 &&
      request.url.search(logos) === -1 &&
      request.url.search(staticos) === -1 &&
      request.url.search(busquedas) === -1 &&
      request.url.search(busquedaCiudadesFarmacias) === -1 &&
      request.url.search(errors) === -1 &&
      !request.url.includes(ownedCarrito) &&
      !location.pathname.includes(checkoutComplete)
    ) {
      this.store.dispatch(new GlobalActions.ShowLoader());
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          const siteDown = localStorage.getItem('site_down')
          if ((request.url.includes(loadPublicData)) &&
            (error.status === 504 || error.status === 0) &&
            siteDown === 'false') {
            localStorage.setItem('site_down', 'true')
            location.href = '/mantenimiento';
          }
          if (request.url.search(busquedaProducto) !== -1 ||
            request.url.search(busquedaContenidos) !== -1 ||
            request.url.search(getClientData) !== -1
          ) {
            location.href = '/pretty-url';
            return throwError(error);
          }
          const errorSender = { ...error, body: request.body, params: request.params }
          this.store.dispatch([new GlobalActions.HideLoader(), new GlobalActions.SendErrors(errorSender)]);
          let errorMsg = '';
          if (error.error instanceof ErrorEvent && request.url.search(carrito) === -1) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            if (error.error.message) {
              this.notif.openNotif(
                'Error!',
                error.error.message,
                { alerta: true },
                3500
              );
            }
          }
          return throwError(errorMsg);
        }),
        finalize(() => {
          this.store.dispatch(new GlobalActions.HideLoader());
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
