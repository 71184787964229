import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { interval } from 'rxjs';
import { CuentasActions } from 'src/app/store/cuentas/cuentas.actions';
@Injectable()
export class UpdateSessionService {
  constructor(private store: Store) {}
  start() {
    const every$ = interval(30000);
    every$.subscribe(() => {
      this.store.dispatch(CuentasActions.CheckToken);
    });
  }
}
