import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class TokenService {
  constructor() {}

  decodeNombre(token: string) {
    return this.getDecodedToken(token)?.user?.firstname;
  }
  decodeApellido(token: string) {
    return this.getDecodedToken(token)?.user?.lastname;
  }
  decodeCliente(token: string) {
    return this.getDecodedToken(token)?.user?.client;;
  }
  decodeTelefono(token: string) {
    return this.getDecodedToken(token)?.user?.phone;
  }
  decodeId(token: string) {
    return this.getDecodedToken(token)?.user?.id;
  }
  decodeExpiration(token: string) {
    return this.getDecodedToken(token)?.exp;
  }
  decodeEmail() {}
  isExpired(token: string): boolean {
    const expired = this.isExpiredToken(token) ? true : false;
    return expired;
  }
  isRefresheable(refreshTime) {
    const date = moment(new Date());
    let returnValue;
    try {
      const expiration = moment(refreshTime);
      returnValue = date >= expiration;
    } catch (error) {}
    if (returnValue !== false) {
      returnValue = true;
    }
    return returnValue;
  }
  getRefreshTime(token: string): moment.Moment {
    const date = moment(new Date());
    const expiration = moment(this.getTokenExpiration(token));
    const time = Math.abs(date.diff(expiration, 'seconds'));
    expiration.add(time, 'seconds');
    return expiration;
  }
  private getTokenExpiration(token: string) {
    try {
      return this.getDecodedToken(token).exp * 1000;
    } catch (error) {}
    return 0;
  }
  private isExpiredToken(token: string) {
    const date = moment(new Date());
    let returnValue;
    try {
      const expiration = moment(this.getTokenExpiration(token));
      returnValue = date >= expiration;
    } catch (error) {}
    if (returnValue !== false) {
      returnValue = true;
    }
    return returnValue;
  }
  private getDecodedToken(token: string): any {
    try {
      const decodedToken = jwt_decode(token);
      return decodedToken;
    } catch (error) {
      return null;
    }
  }
}
