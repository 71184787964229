import { GlobalActions } from 'src/app/store/global/global.actions';
import { FarmaciasService } from 'src/app/services/farmacias/farmacias.service';
import { environment } from 'src/environments/environment';
import { CartActions } from 'src/app/store/cart/cart.actions';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as moment from 'moment';
import { tap } from 'rxjs';
import { FavoritosService } from 'src/app/services/extras/favoritos.service';
import { GestionUsuariosService } from 'src/app/services/usuarios/gestion.service';
import { SubscripcionesService } from 'src/app/services/usuarios/subscripciones.service';
import { NotificacionService } from 'src/app/services/utility/notificacion.service';
import {
  rolDefault,
  RolesHabilitados,
} from 'src/app/services/utility/publico-headers';
import { TokenService } from 'src/app/services/utility/token.service';
import { CuentasActions } from './cuentas.actions';
import * as urls from 'src/app/services/utility/urls';
export interface Roles {
  items: any[];
  errorMsg: string;
  error: boolean;
}

export class CuentasStateModel {
  public cuenta: string;
  public rol: string;
  public rolError?: boolean;
  public token: string;
  public telefono?: string;
  public refresh: string;
  public refreshTime: any;
  public roles: Roles;
  public social: any;
  public socialProvider: string;
  public emailRecupero?: boolean;
  public favoritos: any[];
  public recoveryCheck?: boolean;
  public allowedTrials?: number;
  public currentTrials?: number;
  public loggedInControl: boolean;
}

const defaults = {
  cuenta: '',
  rol: rolDefault,
  token: '',
  refresh: '',
  refreshTime: null,
  roles: {
    items: [],
    error: false,
    errorMsg: '',
  },
  newCuentaForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: { required: '' },
  },
  social: false,
  socialProvider: '',
  favoritos: [],
  loggedInControl: false,
};

@State<CuentasStateModel>({
  name: 'cuentas',
  defaults,
})
@Injectable()
export class CuentasState {
  static isFavorito(indice: number, type: string) {
    return createSelector([CuentasState], (state: CuentasStateModel) => {
      const filtrado = state.favoritos.find((item) => item.id === indice && item.type === type);
      if (filtrado) {
        return true;
      }
      return false;
    });
  }
  constructor(
    private gestionService: GestionUsuariosService,
    private tokenService: TokenService,
    private favoritosService: FavoritosService,
    private subscripcionesService: SubscripcionesService,
    private notificacionService: NotificacionService,
    private farmaciaService: FarmaciasService,
  ) { }
  @Action(CuentasActions.UpdateCuenta)
  updateCuenta(
    { getState, setState }: StateContext<CuentasStateModel>,
    { cuenta }: CuentasActions.UpdateCuenta
  ) {
    const state = getState();
    setState({ ...state, cuenta, emailRecupero: false });
  }
  @Action(CuentasActions.UpdateState)
  updateState(
    { setState }: StateContext<CuentasStateModel>,
    { newState }: CuentasActions.UpdateState
  ) {
    setState(patch({ ...newState }));
  }
  @Action(CuentasActions.CheckToken)
  CheckToken({ getState, dispatch }: StateContext<CuentasStateModel>) {
    const state = getState();
    const expired = this.tokenService.isExpired(state.token);
    if (expired) {
      dispatch(new CuentasActions.CheckRefresh());
    }
  }
  @Action(CuentasActions.CheckRefresh)
  CheckRefresh({ getState, dispatch }: StateContext<CuentasStateModel>) {
    const state = getState();
    if (state.token) {
      const expiredRefresh = this.tokenService.isRefresheable(
        state.refreshTime
      );
      const expired = this.tokenService.isExpired(state.token);
      if (expiredRefresh) {
        dispatch(new CuentasActions.LogOut());
      } else {
        if (expired) {
          dispatch(new CuentasActions.LoadRefresh());
        }
      }
    }
  }

  @Action(CuentasActions.LogOut)
  LogOut({ getState, setState, dispatch }: StateContext<CuentasStateModel>) {
    const state = getState();
    setState(
      patch({
        cuenta: '',
        token: '',
        refresh: '',
        rol: rolDefault,
        rolError: false,
        social: false,
        socialProvider: '',
        refreshTime: '',
        favoritos: [],
        loggedInControl: false,
      })
    );
    dispatch(new CartActions.ClearCarritoData());
    localStorage.clear();
    sessionStorage.clear();
  }
  @Action(CuentasActions.UpdateRol)
  UpdateRol(
    { getState, setState }: StateContext<CuentasStateModel>,
    { rol }: CuentasActions.UpdateRol
  ) {
    if (RolesHabilitados.includes(rol)) {
      const state = getState();
      setState(patch({ rol: rol }));
    }
  }
  @Action(CuentasActions.CuentaConfirm)
  CuentaConfirm({ }: StateContext<CuentasStateModel>) { }
  @Action(CuentasActions.Login)
  Login(
    { getState, setState }: StateContext<CuentasStateModel>,
    { token, refresh }: CuentasActions.Login
  ) {
    const state = getState();
    const refreshTime: moment.Moment = this.tokenService.getRefreshTime(token);
    const telefono = this.tokenService.decodeTelefono(token);
    setState(
      patch({
        token: token,
        telefono,
        refresh: refresh,
        refreshTime: refreshTime.toString(),
        loggedInControl: true
      })
    );
  }

  @Action(CuentasActions.UpdateDatos)
  UpdateDatos(
    { getState, dispatch }: StateContext<CuentasStateModel>,
    { datos }: CuentasActions.UpdateDatos
  ) {
    const state = getState();
    return this.gestionService.update(datos, state.rol).pipe(
      tap(
        (updateResult) => {
        },
        (err) => { }
      )
    );
  }
  @Action(CuentasActions.LoadRefresh)
  LoadRefresh({ getState, dispatch }: StateContext<CuentasStateModel>) {
    const state = getState();
    return this.gestionService.refreshToken(state.refresh, state.rol).pipe(
      tap(
        (loginResult) => {
          dispatch(
            new CuentasActions.Login(
              loginResult.token,
              loginResult.refresh_token
            )
          );
        },
        (err) => {
          dispatch(new CuentasActions.LogOut());
        }
      )
    );
  }
  @Action(CuentasActions.GenerateRecovery)
  GenerateRecovery({ getState, setState }: StateContext<CuentasStateModel>) {
    const state = getState();
    return this.gestionService.getRecoveryCode(state.cuenta).pipe(
      tap((recovResult) => {
        setState(
          patch({
            emailRecupero: true,
          })
        );
      })
    );
  }
  @Action(CuentasActions.LoadLogin)
  LoadLogin(
    { getState, dispatch }: StateContext<CuentasStateModel>,
    { password }: CuentasActions.LoadLogin
  ) {
    const state = getState();
    return this.gestionService.login(state.cuenta, password, state.rol).pipe(
      tap((loginResult) => {
        dispatch(
          new CuentasActions.Login(loginResult.token, loginResult.refresh_token)
        );
        if (state.rol === 'Vendedor') {
          dispatch(new GlobalActions.ShowLoader());
          this.farmaciaService.getFarmaciaAccessToken()
            .subscribe((access: any) => {
              const url = new URL(environment.adminUrl + urls.adminerAccess + '?token=' + access.token.toString());
              window.location.href = url.toString();
              dispatch(new CuentasActions.LogOut());
              return;
            });
        }
      })
    );
  }
  @Action(CuentasActions.LoginSocial)
  LoginSocial({ getState, dispatch }: StateContext<CuentasStateModel>) {
    const state = getState();
    return this.gestionService.loginSocial(state.social, state.rol).pipe(
      tap((loginResult) => {
        dispatch(
          new CuentasActions.Login(loginResult.token, loginResult.refresh_token)
        );
      })
    );
  }

  @Action(CuentasActions.CheckRecovery)
  CheckRecovery(
    { getState, setState }: StateContext<CuentasStateModel>,
    { recovery }: CuentasActions.CheckRecovery
  ) {
    const state = getState();
    return this.gestionService.checkRecoveryCode(state.cuenta, recovery).pipe(
      tap((checkResult) => {
        setState(
          patch({
            recoveryCheck: checkResult.state ? true : false,
            allowedTrials: checkResult.allowed_trials,
            currentTrials: checkResult.current_trials,
          })
        );
      })
    );
  }
  @Action(CuentasActions.ClearRecovery)
  ClearRecovery(
    { getState, setState }: StateContext<CuentasStateModel>,
  ) {
    const state = getState();
    setState(
      patch({
        currentTrials: 0,
      })
    );
  }
  @Action(CuentasActions.ChangePassword)
  ChangePassword(
    { getState, setState }: StateContext<CuentasStateModel>,
    { password, recovery }: CuentasActions.ChangePassword
  ) {
    const state = getState();
    return this.gestionService
      .changePassword(state.cuenta, recovery, password)
      .pipe(
        tap(
          (checkResult) => {
            setState(
              patch({
                recoveryCheck: null,
                emailRecupero: null,
              })
            );
          },
          (err) => {
            throw new Error('');
          }
        )
      );
  }

  @Action(CuentasActions.Registro)
  Registro(
    { dispatch, setState }: StateContext<CuentasStateModel>,
    { data }: CuentasActions.Registro
  ) {
    return this.gestionService.registro(data).pipe(
      tap((loginResult) => {
        setState(
          patch({
            cuenta: data.email,
            rol: rolDefault,
          })
        );
        dispatch(new CuentasActions.LoadLogin(data.password));
      })
    );
  }
  @Action(CuentasActions.LoadFavorites)
  LoadFavorites({ setState }: StateContext<CuentasStateModel>) {
    return this.favoritosService.getFavoritos().pipe(
      tap((favresult) => {
        const favoritos = favresult?.wishes.map((item) => ({ id: item.id, type: item.type }));
        setState(
          patch({
            favoritos: favoritos,
          })
        );
      })
    );
  }
  @Action(CuentasActions.AddFavorito)
  AddFavorites(
    { getState, setState }: StateContext<CuentasStateModel>,
    { publicationId, type }: CuentasActions.AddFavorito
  ) {
    return this.favoritosService.addFavorito(publicationId, type).pipe(
      tap((favresult) => {
        const state = getState();
        setState(
          patch({
            favoritos: [...state.favoritos, { id: publicationId, type }],
          })
        );
      })
    );
  }
  @Action(CuentasActions.RemoveFavorito)
  removeFavorito(
    { getState, setState, dispatch }: StateContext<CuentasStateModel>,
    { publicationId, type }: CuentasActions.RemoveFavorito
  ) {
    return this.favoritosService.removeFavorito(publicationId, type).pipe(
      tap((favresult) => {
        const state = getState();
        const favoritos = state.favoritos.filter(
          (item) => item.id !== publicationId
        );
        setState(
          patch({
            favoritos: favoritos,
          })
        );
      })
    );
  }
  @Action(CuentasActions.Subscribirse)
  Subscribirse(
    { getState, setState }: StateContext<CuentasStateModel>,
    { email }: CuentasActions.Subscribirse
  ) {
    return this.subscripcionesService.subscribirse(email).pipe(
      tap((mensaje) => {
        this.notificacionService.openNotif(
          '',
          mensaje.mensaje,
          { success: true },
          3000
        );
      })
    );
  }
  @Action(CuentasActions.Desubscribirse)
  Desubscribirse(
    { getState, setState }: StateContext<CuentasStateModel>,
    { token }: CuentasActions.Desubscribirse
  ) {
    return this.subscripcionesService.dessubscribirse(token).pipe(
      tap((mensaje) => {
      })
    );
  }
  @Action(CuentasActions.LoadRoles)
  LoadRoles(
    { getState, setState, dispatch }: StateContext<CuentasStateModel>,
    { cuenta }: CuentasActions.LoadRoles
  ) {
    const state = getState();
    return this.gestionService.getRoles(cuenta).pipe(
      tap(
        (rolesArray) => {
          let rol: any = {};
          if (rolesArray.roles.length === 1) {
            rol.rol = rolesArray.roles[0]?.name;
          }
          dispatch(CuentasActions.CuentaConfirm);
          setState(
            patch({
              cuenta,
              ...rol,
              roles: {
                ...state.roles,
                items: rolesArray.roles,
              },
            })
          );
        },
        (err) => {
          setState(
            patch({
              cuenta: '',
              rol: rolDefault,
              rolError: state.social ? true : false,
              roles: {
                ...state.roles,
                error: true,
                errorMsg: err.message,
              },
            })
          );
        }
      )
    );
  }
}
