import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Info } from 'src/app/store/publico/publico.state';
import { environment } from 'src/environments/environment';
import * as urls from '../utility/urls';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  getMenus(): Observable<any> {
    return this.http.get<any>(environment.api + urls.menus);
  }
  getInfo(): Observable<{data:Info}> {
    return this.http.get<any>(environment.api + urls.pageInfo);
  }

}
