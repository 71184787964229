import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notificacion-snack',
  templateUrl: './notificacion-snack.component.html',
  styleUrls: ['./notificacion-snack.component.scss'],
})
export class NotificacionSnackComponent implements OnInit {
  @Output() closed = new EventEmitter<any>(false);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {}

  ngOnInit(): void {}
}
