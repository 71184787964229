import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { themes } from './themes';
const tinycolor = require("tinycolor2");
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable()
export class ThemingService {
  theme = new BehaviorSubject(themes[1]);
  storageWatch = new BehaviorSubject(false);
  themeDescription = 'theme-siemprebien';
  primaryColorPalette: Color[] = [];
  primaryDarkPalette: Color[] = [];
  accentColorPalette: Color[] = [];
  constructor(private ref: ApplicationRef) {
    // initially trigger dark mode if preference is set to dark mode on system
    const localSetting = localStorage.getItem(this.themeDescription)
      ? localStorage.getItem(this.themeDescription)
      : undefined;
    const darkModeOn =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (darkModeOn && localSetting === undefined) {
      this.theme.next(themes[0]);
      localStorage.setItem(this.themeDescription, themes[0]);
    } else {
      if (localSetting) {
        this.theme.next(localSetting);
      } else {
        this.theme.next(themes[1]);
        localStorage.setItem(this.themeDescription, themes[1]);
      }
    }
    window.addEventListener('storage', (e) => {
      if (e.key === this.themeDescription && e.newValue && themes.includes(e.newValue)) {
        this.storageWatch.next(true);
        this.theme.next(e.newValue);
        this.ref.tick();
      }
    });
    // watch for changes of the preference
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        const turnOn = e.matches;
        this.theme.next(turnOn ? themes[0] : themes[1]);
        localStorage.setItem(
          this.themeDescription,
          turnOn ? themes[0] : themes[1]
        );
        // trigger refresh of UI
        this.ref.tick();
      });
  }
  nextLocal(theme: string) {
    localStorage.setItem(this.themeDescription, theme);
  }
  savePrimaryColor(color: string = '#4f177d') {
    this.primaryColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.primaryColorPalette, '--primary-color');
  }
  savePrimaryDark(color: string) {
    this.primaryColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.primaryColorPalette, '--primary-color');
  }
  saveAccentColor(color: string = '#00A98C') {
    this.accentColorPalette = this.computeColors(color);
    this.setThemeGlobal(this.accentColorPalette, '--accent-color');
  }
  setThemeGlobal(colors: Color[], theme: string){
      colors.forEach(color => {
          document.documentElement.style.setProperty(
            `${theme}-${color.name}`,
            color.hex
          );
          document.documentElement.style.setProperty(
            `${theme}-contrast-${color.name}`,
            color.darkContrast ? 'rgba(black, 0.87)' : 'white'
          );
        });
  }

  multiply(rgb1: any, rgb2: any) {
    rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
		rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
		rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
		return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  }
  private computeColors(hex: string): Color[] {
    var baseLight = tinycolor('#ffffff');
    var baseDark = this.multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
    var baseTriad = tinycolor(hex).tetrad();
    return [
      this.getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
      this.getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
      this.getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(65), 'A100'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(55), 'A200'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(45), 'A400'),
      this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(40), 'A700')
    ];
  }
  private getColorObject(value: string, name: string): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }
}
