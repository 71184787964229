<div class="relative flex items-center  py-4 pl-4 pr-2 bg-white">
  <div class="flex flex-row items-center w-auto pb-0">
    <div>
      <ng-container *ngIf='data.success '>
        <svg class="w-6 sm:w-5 h-6 sm:h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </ng-container>
      <ng-container *ngIf="data.purchaseSuccess">
        <svg class="w-6 sm:w-5 h-6 sm:h-5 text-green-400" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5 18c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-3.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm13.257-14.5h-1.929l-3.473 12h-13.239l-4.616-11h2.169l3.776 9h10.428l3.432-12h4.195l-.743 2zm-13.537 4.183l-2.325-2.183-1.395 1.435 3.746 3.565 6.559-6.592-1.422-1.408-5.163 5.183z" />
      </svg>
      </ng-container>
      <ng-container *ngIf='data.favorite'>
        <svg class="w-6 sm:w-5 h-6 sm:h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </ng-container>
      <ng-container *ngIf='data.info||((!data.success&&!data.purchaseSuccess&&!data.favorite)&&!data.alerta)'>
        <svg class="w-6 sm:w-5 h-6 sm:h-5 text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
          fill="currentColor">
          <path fill-rule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd" />
        </svg>
      </ng-container>
      <ng-container *ngIf='data.alerta'>
        <svg class="w-6 sm:w-5 h-6 sm:h-5 text-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
          fill="currentColor">
          <path fill-rule="evenodd"
            d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
            clip-rule="evenodd" />
        </svg>
      </ng-container>
    </div>
    <div class="text-sm text-gray-500 font-medium ml-3">{{data.title}}</div>
  </div>
  <div class="text-base tracking-wide text-black ml-4">{{data.mensaje}}</div>
  <div class="ml-4">
    <button matRipple (click)='closed.emit(true)'>
      <mat-icon class="text-sm w-auto  h-auto align-middle">clear</mat-icon>
    </button>
  </div>
</div>
