<div class="relative flex items-start flex-col  py-4 pl-4 pr-2 text-black">
  <div class="flex flex-row items-center w-auto pb-0">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6  h-6  text-green-400" fill="none" viewBox="0 0 24 24"
        stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <div class="text-sm  font-medium ml-3">{{data.mensaje}}</div>
  </div>
  <div class="flex flex-1 w-full justify-center flex-row items-center pb-0 ">
    <div class="flex  mx-2">
      <button (click)='confirm.emit(true)' mat-button class=" text-base">{{data.botonCancelacion|titlecase}}</button>
    </div>
    <div class="flex mx-2">
      <button (click)='cancel.emit(true)' mat-button class=" text-base">{{data.botonConfirmacion|titlecase}}</button>
    </div>
  </div>
</div>