<div class="app-splash-inner">
  <div class="absolute right-1/2 z-50  bottom-1/2  transform translate-x-1/2 translate-y-1/2  ">
    <div class="loader animate-spin bg-transparent rounded-full  h-32 w-32 border-4 border-transparent "></div>
  </div>
  <div class="text-accent-500 z-50  font-bold text-2xl text-center">
    <img class='w-14 h-14' [src]="logoUrl">
  </div>
  <!-- <div class="app-sub-title">{{data?.title}}{{title}}</div> -->
</div>
<div class="app-splash-screen">

</div>
