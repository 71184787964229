import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DarkModeComponent } from './dark-mode/dark-mode.component';
import { ScrollUpComponent } from './scroll-up/scroll-up.component';
import { ThirdLibModule } from 'src/app/shared/third-lib/third-lib.module';
import { NombreUsuarioComponent } from './nombre-usuario/nombre-usuario.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { RouterModule } from '@angular/router';
import { NombreFarmaciaComponent } from './nombre-farmacia/nombre-farmacia.component';
import { HeaderStageComponent } from './header-stage/header-stage.component';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [DarkModeComponent, ScrollUpComponent, NombreUsuarioComponent, PaginatorComponent, NombreFarmaciaComponent, HeaderStageComponent, LoaderComponent],
  imports: [
    CommonModule,
    ThirdLibModule,
    RouterModule,
  ],
  exports: [
    DarkModeComponent,
    ScrollUpComponent,
    NombreUsuarioComponent,
    NombreFarmaciaComponent,
    PaginatorComponent,
    HeaderStageComponent,
    LoaderComponent
  ]
})
export class GlobalesModule { }
