import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as urls from '../utility/urls';
@Injectable({
  providedIn: 'root',
})
export class DevolucionesService {
  constructor(private http: HttpClient) {}

  createDevolucion(
    data: {firstname: string,
    lastname: string,
    email: string,
    phone: string,
    billing_code: string,
    purchase_date: string,
    address: string,
    comments: string}
  ): Observable<any> {
    return this.http.post<any>(environment.api + urls.createDevolucion, {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      billing_code: data.billing_code,
      purchase_date: data.purchase_date,
      address: data.address,
      comments: data.comments,
    });
  }
}
