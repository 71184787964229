import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirmacion-snack',
  templateUrl: './confirmacion-snack.component.html',
  styleUrls: ['./confirmacion-snack.component.scss'],
})
export class ConfirmacionSnackComponent implements OnInit {
  @Output() cancel = new EventEmitter<any>(false);
  @Output() confirm = new EventEmitter<any>(false);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {}

  ngOnInit(): void {}
}
