export const siemprebienConfiguration = {
    site_name: 'Siemprebien',
    title: 'Siemprebien',
    theme_color: '#5b2481',
    Abstract: 'Siemprebien | Comprá Online, Viví en Línea.',
    author: 'Siemprebien',
    copyright: 'Siemprebien',
    site_down_favicon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS66XNFqTg2yhQfbLqHKACEUQmPCiEjtrrk4g&usqp=CAU',
    site_down_title: 'Siemprebien',
    client_att_email: 'atencionalcliente@siemprebien.com.ar',
    optional_prescription: true,
}