import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as urls from '../utility/urls';

@Injectable({
  providedIn: 'root',
})
export class ErroresService {
  constructor(private http: HttpClient) {}

  sendError(error: any): Observable<any> {
    return this.http.post<any>(environment.api + urls.sendError, {
      error,
    });
  }
}
