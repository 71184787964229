import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-stage',
  templateUrl: './header-stage.component.html',
  styleUrls: ['./header-stage.component.scss']
})
export class HeaderStageComponent implements OnInit {
  apiUrl:string = environment.api;
  stage:string = environment.stage;
  stageTitle: string;
  stageVisible: boolean = false;
  environments: Map<string,string>;
  constructor() { 
    this.environments = new Map;
    this.environments.set('dev','Desarrollo');
    this.environments.set('qa','QA');
  }

  ngOnInit(): void {
    this.setStageTitle();
  }

  setStageTitle(): void {
    if(this.environments.has(this.stage)) {
      this.stageTitle = this.environments.get(this.stage);
      this.stageVisible = true;
    }
    else {
      this.stageTitle = 'Offline';
      this.stageVisible = false;
    }
  }
}
