import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FarmaciaGuard } from './utility/guards/farmacia.guard';

const routes: Routes = [
  { path: 'login', pathMatch: 'full', redirectTo: 'cuentas/login' },
  { path: 'logout', pathMatch: 'full', redirectTo: 'cuentas/logout' },
  {
    path: '',
    loadChildren: () =>
      import('./pages/publico/publico.module').then((m) => m.PublicoModule),
  },
  {
    path: 'farmacias',
    loadChildren: () =>
      import('./pages/farmacia/farmacia.module').then((m) => m.FarmaciaModule),
    canActivate: [FarmaciaGuard],
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: 'mantenimiento',
    loadChildren: () =>
      import('./pages/mantenimiento/mantenimiento.module').then(
        (m) => m.MantenimientoModule
      ),
  },
  {
    path: 'pretty-url',
    loadChildren: () =>
      import('./components/globales/pretty-url/pretty-url.module').then(
        (m) => m.PrettyUrlModule
      ),
  },
  { path: '**', redirectTo: 'pretty-url' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
