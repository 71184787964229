import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as urls from '../utility/urls';
@Injectable({
  providedIn: 'root',
})
export class SubscripcionesService {
  constructor(private http: HttpClient) {}

  subscribirse(email: string): Observable<any> {
    return this.http.post<any>(environment.api + urls.subscribirse, {
      email,
    });
  }
  dessubscribirse(token: string): Observable<any> {
    return this.http.post<any>(environment.api + urls.deleteSubscribirse, {
      token,
    });
  }
}
