export namespace CuentasActions {
  export class UpdateCuenta {
    static readonly type = '[Cuentas] Update Cuenta';
    constructor(public cuenta: string) {}
  }
  export class GenerateRecovery {
    static readonly type = '[Cuentas] Generate Recovery';
    constructor() {}
  }
  export class CheckRecovery {
    static readonly type = '[Cuentas] Check Recovery';
    constructor(public recovery: string) {}
  }
  export class ClearRecovery {
    static readonly type = '[Cuentas] Clear Recovery';
  }
  export class LoadRoles {
    static readonly type = '[Cuentas] Load Roles';
    constructor(public cuenta: string) {}
  }
  export class LoadLogin {
    static readonly type = '[Cuentas] Load Login';
    constructor(public password: string) {}
  }
  export class LoadRefresh {
    static readonly type = '[Cuentas] Load Refresh';
    constructor() {}
  }
  export class Login {
    static readonly type = '[Cuentas] Login';
    constructor(public token: string, public refresh: string) {}
  }
  export class LoginSocial {
    static readonly type = '[Cuentas] Login Social';
    constructor() {}
  }
  export class CuentaConfirm {
    static readonly type = '[Cuentas] Cuenta Confirm';
    constructor() {}
  }
  export class LogOut {
    static readonly type = '[Cuentas] Log Out';
    constructor() {}
  }
  export class Registro {
    static readonly type = '[Cuentas] Registro';
    constructor(public data: any) {}
  }
  export class UpdateRol {
    static readonly type = '[Cuentas] Update Rol';
    constructor(public rol: string) {}
  }
  export class UpdateState {
    static readonly type = '[Cuentas] Update State';
    constructor(public newState: any) {}
  }
  export class UpdateDatos {
    static readonly type = '[Cuentas] Update Datos';
    constructor(public datos: any) {}
  }

  export class CheckToken {
    static readonly type = '[Cuentas] Check Token';
    constructor() {}
  }
  export class CheckRefresh {
    static readonly type = '[Cuentas] Check Refresh';
    constructor() {}
  }
  export class ChangePassword {
    static readonly type = '[Cuentas] Change Password';
    constructor(public recovery: string, public password: string) {}
  }
  export class LoadFavorites {
    static readonly type = '[Cuentas] Load Favorites';
    constructor() {}
  }
  export class AddFavorito {
    static readonly type = '[Cuentas] Add Favorito';
    constructor(public publicationId: number, public type: string) {}
  }
  export class RemoveFavorito {
    static readonly type = '[Cuentas] Remove Favorito';
    constructor(public publicationId: number, public type: string) {}
  }
  export class Desubscribirse {
    static readonly type = '[Cuentas] Desubscribirse';
    constructor(public token: string) {}
  }
  export class Subscribirse {
    static readonly type = '[Cuentas] Subscribirse';
    constructor(public email: string) {}
  }
}
